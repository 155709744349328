import React from 'react'
import "../style/myStyle.scss"

import Layout from '../components/layout'
import InfoCard from '../components/infoCard'
import ShopBanner from '../components/shopBanner'
import SEO from '../components/seo'


const ShopPage = ({location}) => {
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'ViewContent',   {
          content_type: 'product', // required property
          content_ids: 'txpg522i2z' // required property
        });
      }
    }
  }, [])
 return (
   <Layout location={location}>
     <SEO title="St-Martin - Le Tshirt" keywords={[`tshirt`, `home`, `bio`, `engagé`]} />
     <ShopBanner displayMore={false}/>
     <section className="section">
       <div className="columns">
         <div className="column">
           <InfoCard title="Description" logo="../Info.png">
             - T-shirt en coton bio, blanc<br/>
             - Impression au recto et dans le col<br/>
             - Encolure arrondie<br/>
             - Coupe ajustée (mais pas trop)
           </InfoCard>
         </div>
         <div className="column">
           <InfoCard title="Traçabilité" logo="../Trace.png">
             - T-shirt coton biologique originaire du Bangladesh<br/>
             - Impression jets d’encre à Montpellier<br/>
             - Imaginé à Paris
           </InfoCard>
         </div>
         <div className="column">
           <InfoCard title="Fair Wear" logo="../Fair.png">
             - Certification <a href="https://www.fairwear.org/" target="_blank" rel="noreferrer">Fair Wear</a> pour un respect des conditions de travail et de l’environnement<br/>
             - <a href="https://www.fairwear.org/" target="_blank" rel="noreferrer">Découvrez les engagements</a>
           </InfoCard>
         </div>
       </div>
     </section>
     <section className="section">
       <div className="columns shop-card">
         <div className="column">
           <div className="shop-card__container">
            <div class="title">Entretien</div>
           <div className="content">
             - Lavage en machine à 30° max<br/>
             - Laver avec du blanc (pour garder sa splendeur)<br/>
             - Repassage au fer à repasser, sur l'envers
           </div>
           </div>
         </div>
         <div className="column">
           <div className="shop-card__container">
           <div className="title">Taille</div>
           <div className="content">
             - Jacques taille normalement, nous vous conseillons de prendre votre taille habituelle<br/>
             - Hauteur & largeur: 66cm & 47.5cm (M)
           </div>
           </div>
         </div>
       </div>
     </section>
     <section className="section">
       <div className="columns donation-card">
         <div className="column is-two-fifths donation-card__logo">
           <img src="../NoStock.png" alt="stock" />
         </div>
         <div className="column is-three-fifths donation-card__content">
           <div className="donation-card__title">Livraisons</div>
           <div className="donation-card__text">
             - Les t-sirts sont envoyés dans la semaine suivant la fin de la période de pré-commande (et oui on ne stock rien chez St-Martin, donc on envoie une fois les commandes passées et la production finie !)<br/>
             - Livraison en France uniquement, prix fixe de 4,90€, non compris dans le prix du t-shirt.
           </div>
         </div>
       </div>
     </section>
     <section className="section">
       <div className="columns donation-card">
         <div className="column is-three-fifths donation-card__content">
           <div className="donation-card__title">Retours et échanges</div>
           <div className="donation-card__text">
             - Livraison en France uniquement, prix fixe de 4,90€, non compris dans le prix du t-shirt.<br/>
             - Votre t-shirt ne vous convient pas ? Mince alors … écrivez-nous !<br/>
             - Aucun remboursement, mais on vous échange un Jacques contre un autre. Contactez-nous pour organiser cela.
           </div>
         </div>
         <div className="column is-two-fifths donation-card__logo">
           <img src="../Hi.png" alt="Hi banner"/>
         </div>
       </div>
     </section>
   </Layout>
 )
}

export default ShopPage;
