import PropTypes from 'prop-types'
import React from 'react'

const InfoCard = ({ title, logo, children }) => (
  <div className="info-card">
    <div className="info-card-title">
      {logo && <img src={logo} alt="logo" className="info-card-icon"/>}
      {title}
    </div>
    <div className="info-card-content">
      {children}
    </div>
  </div>
  )

InfoCard.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
}

InfoCard.defaultProps = {
  title: ``,
}

export default InfoCard
